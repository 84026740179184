<template>
  <div class="main" :style="image">
    <div class="retour">
      <img 
      @click="goHome"
        :src="img.secondary.retour"
        draggable="false" 
      />
    </div>
    <div class="page-content page1">
      <img 
        class="mainImg"
        :src="img.secondary.image"
        draggable="false" 
      />
      <img 
        class="mainTxt"
        :src="img.secondary.texte"
        draggable="false" 
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      format: 'video/mp4',
      ready: false,
      img: {
        main: require("@/assets/pages/bg3.png"),
        secondary: {
          image: require("@/assets/pages/img3_.png"),
          texte: require("@/assets/pages/txt3_.png"),
          retour: require("@/assets/tests/retour.png"),
        },
      },
    };
  },
  beforeMount() {},
  mounted() {
  },
  methods: {
    goto(route) {
      this.drawer = false;
      this.$routeToLoad.set(route);
      this.$router.push(route)
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({ name: "Home" })
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
  },
  watch: {},
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.buttons {
  position: absolute;
  bottom: 113px;
  // bottom: 97px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 124px;
}

.retour{
  position: absolute;
  left: 45px;
  z-index: 100;
  bottom: 770px;
}

.page-title{
  position: absolute;
  top: 10px;
  align-content: center;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 106%;

  color: #3A4592;
  z-index: 30000;
}

.mainImg{
  position: absolute;
  right: 64px;
  bottom: 71px;
}
.mainTxt{
  position: absolute;
  left: 60px;
  bottom: 41px;
  z-index: 3;
}
.mainVideo, video{
  height: 680px;
  width: 596px;
}

</style>
