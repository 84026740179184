<template>
  <div class="main" :style="image">
    <div class="shadow"></div>
    <div class="presentation">
      <div class="pres-title">POLYCLINIQUE MAYMARD</div>
      <div class="pres-accroche">Tous acteurs de votre santé</div>
      <div class="pres-button">
        <button @click="to({ name: 'Presentation' })">DÉCOUVRIR L'ÉTABLISSEMENT</button>
      </div>
    </div>

    <!-- <div
      id="mysplide"
      class="splide"
      role="group"
      aria-label="Splide Basic HTML Example"
    >
      <div class="splide__track">
        <ul class="splide__list">
          <li
            class="splide__slide"
            :key="index"
            v-for="(button, index) in buttons"
          >
            <Button
              :img="button.img"
              :to="button.to"
              :title="button.title"
              :css="button.css"
            ></Button>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="buttons">
      <Button v-for='(button, index) in buttons' :key="index" :img="button.img" :to="button.to" :title="button.title" :css="button.css"></Button>
    </div>
  </div>
</template>

<script>
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/splide-core.min.css";

import { Splide } from "@splidejs/splide";

import Button from "@/components/Button";

export default {
  components: {
    Button,
    Splide,
  },
  data() {
    return {
      img: {
        main: require("@/assets/tests/accueil.png"),
        secondary: {
          menu1: require("@/assets/menus/menu1.png"),
        },
      },
      buttons: {
        btn0: {
          img: require("@/assets/menus/menu1.png"),
          title: "CHARTE DES DROITS DES PATIENTS",
          to: "CharteDroitsPatients",
          css: "height: 50%"
        },
        btn1: {
          img: require("@/assets/menus/menu2.png"),
          title: "REPRÉSENTANTS DES USAGERS",
          to: "RepresentantsUsagers",
          // css: "height: 130px"
          css: "height: 50%"
        },
        btn2: {
          img: require("@/assets/menus/menu3.png"),
          title: "DIRECTIVES ANTICIPÉES",
          to: "DirectivesAnticipees",
          css: ""
        },
        // btn3: {
        //   img: require("@/assets/menus/menu4.png"),
        //   title: "BOÎTE À <br> IDÉES",
        //   to: "BoiteIdees",
        //   css: ""
        // },
        // btn4: {
        //   img: require("@/assets/menus/menu5.png"),
        //   title: "DEMANDE DE CONTACT",
        //   to: "Contact",
        //   css: ""
        // },
        // btn5: {
        //   img: require("@/assets/menus/menu6.png"),
        //   title: "DÉPÔT D'AVIS",
        //   to: "DepotAvis",
        //   css: ""
        // },
        btn6: {
          img: require("@/assets/menus/menu7.png"),
          title: "MON ESPACE SANTÉ",
          to: "MonEspaceSante",
          css: ""
        },
        btn7: {
          img: require("@/assets/menus/menu8.png"),
          title: "LIVRET D'ACCUEIL",
          to: "LivretAccueil",
          css: ""
        },
      },
    };
  },
  beforeMount() {},
  mounted() {
    // new Splide("#mysplide", {
    //   gap: 19,
    //   fixedWidth: 251,
    //   // arrows: false,
    //   pagination: false,
    //   classes: {
    //     arrows: 'splide__arrows arrows_',
    //     arrow : 'splide__arrow arrow_',
    //     prev  : 'splide__arrow--prev arrow-prev',
    //     next  : 'splide__arrow--next arrow-next',
    //   }
    // }).mount();
  },
  methods: {
    goto(route) {
      this.drawer = false;
      this.$routeToLoad.set(route);
      this.$router.push(route);
      // document.dispatchEvent(new CustomEvent("loadroute"))
    },
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({ name: "Home" });
      // document.dispatchEvent(new CustomEvent("loadroute"))
    },
    to(route) {
      this.$routeToLoad.set(route);
      this.$router.push(route);
      // document.dispatchEvent(new CustomEvent("loadroute"))
    },
  },
  watch: {},
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.buttons {
  width: 100%;
  height: 251px;
  position: absolute;
  bottom: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19px;
}

#mysplide {
  left: 155px;
  bottom: 55px;
  width: calc(100% - 360px);
  position: absolute;
  // transform: translate(0, -50%);
}

.presentation {
  position: absolute;
  top: 160px;
  right: 164px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  color: #ffffff !important;
  font-family: "Poppins" !important;
  text-align: right;

  .pres-title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-family: "Poppins-Regular" !important;
    font-size: 130px;
    line-height: 85%;
    max-width: 1000px;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .pres-accroche {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 128%;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .pres-button {
    button {
      margin-top: 37px;
      color: white;
      background: #d27b27;
      font-weight: 700;
      font-size: 20px;
      line-height: 128%;
      text-transform: uppercase;
      padding: 18px 30px;
      text-align: center;
      border-radius: 10px;
    }
  }
}

.shadow {
  /* Rectangle 2 */

  box-sizing: border-box;

  position: absolute;
  width: 1920px;
  height: 100%;
  left: 0px;
  top: 10px;

  background: linear-gradient(
    270deg,
    rgba(58, 69, 146, 0.9) 25.27%,
    rgba(58, 69, 146, 0) 97.18%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

</style>


<style lang="scss">
.arrows_{
  background-color: unset;
  opacity: 1;
}

.arrow_{

}

.arrow-next{
  background-image: url(./../assets/img/arrow-prev.png);
  height: 60px;
  width: 30px;
  right: calc(-60px - 30px);
  background-color: unset;
  transform: rotate(180deg);
  transform-origin: 45% 28%;
  svg{
    display: none;
  }
  
}

.arrow-prev{
  background-image: url(./../assets/img/arrow-prev.png);
  height: 60px;
  width: 30px;
  left: calc(-60px - 30px);;
  background-color: unset;
  svg{
    display: none;
  }
}
</style>