<template>
<div>
    <v-app-bar
      style="z-index: 9"
      color="#3A4592"
      dark
      height="56"
      class="header"
    >
      <!-- <v-app-bar-nav-icon class="menubtn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <!-- <v-toolbar-title @click.stop="drawer = !drawer" style="font-size: 28px">MENU</v-toolbar-title> -->
      <!-- <v-toolbar-items style="font-size: 28px;position:absolute;left:50%;transform:translate(-50%);align-items:center">{{pageCount}}</v-toolbar-items> -->
     <div class="header-contact">
      <div class="mail">
          <img :src="require('@/assets/img/mail.png')"/>
          <span>contact.maymard@almaviva-sante.com</span>
      </div>
      <div class="tel">
          <img :src="require('@/assets/img/tel.png')"/>
          <span>Tel : 04.95.55.39.39</span>
      </div>
     </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      top
      right
      :overlay-opacity="0.9"
      height="100%"
      width="1051"
      style="background: #3A4592;margin-top:55px;z-index:1000;height: 100%"
    >
    <div :style="'position:absolute;top:2px;right: 30px;background-image: url('+require('@/assets/tests/close.png')+') ; height:48px;width: 48px'" @click.stop="drawer = !drawer"></div>
    <div class="container">
      <button @click="goto({name: 'Presentation'})"><div class="menu-item"> Présentation </div></button>
      <div class="line"></div>
      <button @click="goto({name: 'CharteDroitsPatients'})"><div class="menu-item"> Charte des droits des patients </div></button>
      <div class="line"></div>
      <button @click="goto({name: 'RepresentantsUsagers'})"><div class="menu-item"> Représentants des usagers </div></button>
      <div class="line"></div>
      <button @click="goto({name: 'DirectivesAnticipees'})"><div class="menu-item"> Directives anticipées </div></button>
      <div class="line"></div>
      <!-- <button @click="goto({name: 'BoiteIdees'})"><div class="menu-item"> Boite à idées </div></button>
      <div class="line"></div> -->
      <!-- <button @click="goto({name: 'Contact'})"><div class="menu-item"> Contact </div></button>
      <div class="line"></div> -->
      <!-- <button @click="goto({name: 'DepotAvis'})"><div class="menu-item"> Dépôt d'avis </div></button>
      <div class="line"></div> -->
      <button @click="goto({name: 'MonEspaceSante'})"><div class="menu-item"> Mon espace santé </div></button>
      <div class="line"></div>
      <button @click="goto({name: 'LivretAccueil'})"><div class="menu-item"> Livret d'accueil </div></button>
    </div>
    </v-navigation-drawer>
    <div class="subheader" :class="bande2 ? '' : 'subheader-fix'">
      <img :src="require('@/assets/img/logo.png')" class="logo" @click="goto({name: 'Home'})">
      <img style="position:absolute;height:48px;top:33px;right:62px;" :src="require('@/assets/img/hamburger.svg')" class="menubtn2" @click.stop="drawer = !drawer"/>
    </div>
</div>
</template>

<script>
  export default {
    data: () => ({
      drawer: false,
      group: null,
    }),

    methods: {
      goto(route){
        this.drawer = false;
        this.$routeToLoad.set(route)
        this.$router.push(route)
        // document.dispatchEvent(new CustomEvent("loadroute"))
      },
      goHome(){
        this.$routeToLoad.set({name: "Home"})
        // this.$router.push({name: "Home"})
        document.dispatchEvent(new CustomEvent("loadroute"))
      }
    },
    computed:{
      bande2(){
      return (
        this.$route.name == "Home" ||
        this.$route.name == "BoiteIdees" ||
        this.$route.name == "Contact" 
      );
    }
    },

    watch: {
      group () {
        this.drawer = false
      },
    },
  }
</script>

<style lang="scss" scoped>

.header-contact{
  // padding-top: 10px !important;
  color: white;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 63px;
  & > div{
    display: flex;
    align-items: center;
    gap: 15px;
  }
  div span{
    font-family: 'Helvetica' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 128%;
  }
}

.subheader{
  position:absolute;
  top:56px;
  width: 100%;
  height:calc(50px + 56px);
  left:0;
  background:white;
  z-index:18;
  
  .logo{
    position: absolute;
    top: 8px;
    left: 60px;
  }

}

.menubtn {
  margin-left: 62px ;
}

.menubtn .v-btn__content i{
  font-size: 52px;
}

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child{
  margin-left: 34px;
}

.line{
  background: white;
  height: 1px;
  // border: 0.1px solid #3A4592;
  box-shadow: 0 0 1px #3A4592; 
  width: auto;
  margin: 30px 56px 30px 8px;
}

.container{
  height: 100%;
  padding: 180px 42px 262px 132px;
  // padding: 92px 42px 262px 132px;
  color:white;

  .menu-item{
    font-size:30px;
    font-weight: 700;
  }
}

.subheader-fix{
  background: transparent !important;
}

</style>