<template>
  <div class="main" :style="image">
    <div class="page-title">Mon Espace Santé</div>
    <div class="retour">
      <img 
      @click="goHome"
        :src="img.secondary.retour"
        draggable="false" 
      />
    </div>
    <div class="page-content">
      <div class="mainPoster">
       <img 
        :src="img.secondary.poster"
        draggable="false" 
        class="poster"
        @click="startVid"
      /> 
      </div>
      
      <div class="video-container" v-show="ready_">
        <div class="video-bg" @click="exitVideo"></div>
        <div class="close" :style="'background-image: url('+img.secondary.closebtn+')'"></div>
        <div class="video-wrapper">
          <div v-show="videoEnded" class="video-mask video-ended">
            <div class="video-replay"></div>
          </div>
            <video id="videoplayer1" class="mainVideo video-js" preload="auto" >
            <!-- <source :src="video" type="video/mp4" /> -->
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as videojs from 'video.js'
let videoJS = videojs.default
import 'video.js/dist/video-js.css';

export default {
  components: {
  },
  data() {
    return {
      videoplayer: null,
      videoEnded_: false,
      video: require("@/../public/espace_sante.mp4"), 
      format: 'video/mp4',
      ready: false,
      img: {
        main: require("@/assets/pages/bg1.png"),
        secondary: {
          image: require("@/assets/pages/img1.png"),
          texte: require("@/assets/pages/txt1.png"),
          retour: require("@/assets/tests/retour.png"),
          poster: require("@/assets/tests/espace_sante.png"),
        },
      },
    };
  },
  beforeMount() {},
  mounted() {
    let t = this
     t.videoplayer = videoJS("videoplayer1", {
      autoplay: false, inactivityTimeout: 3000,controls: true, preload: 'auto', PictureInPictureToggle: false, muted: true,
      controlBar: {
        fullscreenToggle: false, pictureInPictureToggle: false
        }
      }, 
      function onPlayerReady() {
        t.videoplayer.on("ready", () => {
          // console.log("endTimeout + onRdy")
          t.videoplayer.src({ src: (t.video), type: t.format })

          t.videoplayer.el_.addEventListener('touchstart', () => {
            t.videoplayer.el_.click()
          })
        })
        this.on("click", () => {
          // console.log("click")
          // t.ready = true
          // this.play()
          if(t.videoEnded_){
            t.videoEnded_ = false
            t.videoplayer.play()
          }
        })
        t.videoplayer.on('aderror', () => {
            let currentTime = t.videoplayer.currentTime()
            console.warn(t.videoplayer.error()); //Gives MEDIA_ERR_SRC_NOT_SUPPORTED error
            t.videoplayer.src({ src: (t.video), type: t.format })
            t.videoplayer.currentTime(currentTime)
        })
        t.videoplayer.on('ended', () => {
            // t.videoplayer.controlBar.hide()
            console.log(t.videoplayer)
            t.videoEnded_ = true
            t.videoplayer.el_.classList.add("vjs-playing")
        })
        t.videoplayer.on('play', () => {
          t.videoEnded_ = false
        })
        t.videoplayer.on('timeupdate', () => {
          t.videoEnded_ = false
        })
      })
  },
  methods: {
    startVid(){
      this.ready = true
      if(this.videoplayer) this.videoplayer.play()
    },
    exitVideo(){
      this.ready = false
      if(this.videoplayer) {
        // console.log(this.videoplayer)
        this.videoplayer.currentTime(0)
        this.videoplayer.pause()
      }
    },
    goto(route) {
      this.drawer = false;
      this.$routeToLoad.set(route);
      this.$router.push(route)
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({ name: "Home" })
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
  },
  watch: {},
  beforeDestroy(){
    if(this.videoplayer) this.videoplayer.dispose();
  },
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
    ready_(){
      return this.ready
    },
    videoEnded(){
      return this.videoEnded_
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.buttons {
  position: absolute;
  bottom: 113px;
  // bottom: 97px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 124px;
}

.retour{
  position: absolute;
  left: 45px;
  z-index: 100;
  bottom: 770px;
}

.page-title{
  position: absolute;
  bottom: 820px;
  align-content: center;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 106%;
  font-family: 'Poppins' !important;

  color: #3A4592;
  z-index: 30;
}

.mainTxt{
  position: absolute;
  left: 86px;
  bottom: 66px;
}

.mainImg{
  position: absolute;
  left: 315px;
  top: 140px;
}

.mainPoster{
  position: absolute;
  // right: 250px;
  left: 65.5%;
  // transform: translate(-50%);
  bottom: 141px;
  img{
    position: absolute;  

    height: 608px;
    width: 596px;
    right: 0;
    bottom: 0;
  }
    
  &::after{
    content: "";
    display: block;
    position: absolute;
    bottom: 254px;
    left: -348px;
    width: 100px; 
    height: 100px; 
    background-image: url('../assets/img/playbtn.png');
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none; 
  }
  

}

.video-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .video-bg{
    width: 100%;
    height: 100%;
    position: relative;
    background: #3A4592;
    opacity: 0.8;
    // pointer-events: none;
  }

  .video-wrapper{
      height: 840px;
      // width: 1565px;
      width: 1492px;
      position: absolute;
      top: 139px;
      z-index: 1000;
      left: 178px;

      .mainVideo, video{
        position: relative;
        width: 100% !important;
        height: 100% !important;
      }
  }

  .video-mask{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    left: 0%;
    top: 0%; 
    pointer-events: none;

    // opacity: 0.2;
    // background: black;  
    background-color: rgba(0, 0, 0, 0.40);

    .video-replay{
      position: absolute;
      height: 134px;
      width: 134px;
      background-size: contain;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url('./../assets/img/replay25.png');
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1;
      z-index: 101;
      pointer-events: none;
    }
  }
}



</style>
