import Vue from 'vue';
// import VueRouter, { RawLocation, Route }/*, { RouteConfig }*/ from 'vue-router';
import Router, { NavigationFailureType } from 'vue-router';


import Home from '../views/Home.vue';
import WaitingScreen from '../views/WaitingScreen.vue';

import Presentation from '../views/Presentation.vue';
import CharteDroitsPatients from '../views/CharteDroitsPatients.vue';
import RepresentantsUsagers from '../views/RepresentantsUsagers.vue';
// import RepresentantsUsagers from '../views/RepresentantsUsagers_v2.vue';
import DirectivesAnticipees from '../views/DirectivesAnticipees.vue';
import BoiteIdees from '../views/BoiteIdees.vue';
import Contact from '../views/Contact_v2.vue';
import DepotAvis from '../views/DepotAvis.vue';
import MonEspaceSante from '../views/MonEspaceSante.vue';
import LivretAccueil from '../views/LivretAccueil.vue';


const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name != "NavigationDuplicated") {
      // throw error;
    }
  })
};
Vue.use(Router);

// Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/waitingScreen',
    name: 'WaitingScreen',
    component: WaitingScreen
  },
 /* {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import( '../views/About.vue')
  }*/
  {
    path: '/presentation',
    name: 'Presentation',
    component: Presentation
  },
  {
    path: '/chartedroitspatients',
    name:'CharteDroitsPatients',
    component: CharteDroitsPatients
  },
  {
    path: '/representantsusagers',
    name:'RepresentantsUsagers',
    component: RepresentantsUsagers
  },
  {
    path: '/directivesanticipees',
    name: 'DirectivesAnticipees',
    component: DirectivesAnticipees
  },
  {
    path: '/boiteidees',
    name: 'BoiteIdees',
    component: BoiteIdees
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/depotavis',
    name: 'DepotAvis',
    component: DepotAvis
  },
  {
    path: '/monespacesante',
    name: 'MonEspaceSante',
    component: MonEspaceSante
  },
  {
    path: '/livretaccueil',
    name: 'LivretAccueil',
    component: LivretAccueil
  }
];

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// });
const router = new Router({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
